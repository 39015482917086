import React, { useState, useEffect, useCallback } from "react";
import { Button, Offcanvas, Nav } from "react-bootstrap"; // Fixed NavItem import
import LogoutComponent from "./LogoutComponent";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import PropTypes from "prop-types"; // For prop type checking

const AuthMenu = ({ them }) => { // Renamed to AuthMenu for consistency
  const { isLoggedIn, userData, isModerator } = useAuth();
  const [show, setShow] = useState(false);

  // Handlers
  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => setShow(false), []);

  return (
    <>
      {isLoggedIn ? (
        <>
          <Button variant="none" onClick={handleShow}>
            {userData && (
              <>
                {userData.avatar_url ? (
                  <img
                    src={userData.avatar_url}
                    alt="User avatar"
                    width="40"
                    height="40"
                    className="rounded-circle"
                  />
                ) : (
                  them === 'light' ? (
                    <div
                      style={{ width: 40, height: 40 }}
                      className="d-flex bg-white border-primary text-primary fw-bold rounded-circle align-content-center align-items-center justify-content-center"

                    >
                      {userData.first_name?.[0] || "U"}
                    </div>
                  ) : (
                    <div
                      style={{ width: 40, height: 40 }}
                      className="d-flex bg-primary border-primary text-white fw-bold rounded-circle align-content-center align-items-center justify-content-center"
                    >
                      {userData.first_name?.[0] || "U"}
                    </div>
                  )
                )}
              </>
            )}
          </Button>

          <Offcanvas show={show} onHide={handleClose} placement="end">
  {userData && (
    <>
      {/* Header */}
      <Offcanvas.Header
        closeButton
        className="bg-gradient-primary text-white" // Gradient background
        style={{
          background: "linear-gradient(135deg, #003C7F, #0066CC)", // Custom gradient
        }}
      >
        <div className="d-flex align-items-center">
          {/* Avatar */}
          <div className="me-3">
            {userData.avatar_url ? (
              <img
                src={userData.avatar_url}
                alt="User avatar"
                width="70"
                height="70"
                className="rounded-circle" // Circular avatar
              />
            ) : (
              <div
                style={{ width: 70, height: 70 }}
                className="d-flex bg-white text-primary fw-bold rounded-circle align-items-center justify-content-center"
              >
                {userData.first_name?.[0] || "U"}
              </div>
            )}
          </div>

          {/* User Details */}
          <div>
            <h5 className="mb-0">{userData.name}</h5>
            <small className="text-white-50">{userData.email}</small>
          </div>
        </div>
      </Offcanvas.Header>

      {/* Body */}
      <Offcanvas.Body className="p-3">
        {/* Moderator Link */}
        {isModerator && (
          <div className="mb-3 pb-2 border-bottom">
            <Link
              to="/moderator/business/requests"
              className="text-decoration-none text-dark d-block py-2"
            >
              <i className="bi bi-person-gear me-2"></i> {/* Bootstrap icon */}
              Moderator
            </Link>
          </div>
        )}

        {/* Manage Account Link */}
        <div className="mb-3 pb-2 border-bottom">
          <Link
            to="/account/manage"
            className="text-decoration-none text-dark d-block py-2"
          >
            <i className="bi bi-gear me-2"></i> {/* Bootstrap icon */}
            Manage Account
          </Link>
        </div>

        {/* Logout Button */}
        <div className="mt-4">
          <LogoutComponent />
        </div>
      </Offcanvas.Body>
    </>
  )}
</Offcanvas>
        </>
      ) : (
        <Nav.Item> {/* Fixed NavItem usage */}
          <Link
            className="text-white text-decoration-none ms-2"
            to="/account/register"
          >
            Register
          </Link>
          <Link
            className="text-white text-decoration-none ms-2"
            to="/account/login"
          >
            Login
          </Link>
        </Nav.Item>
      )}
    </>
  );
};

// Prop type validation
AuthMenu.propTypes = {
  them: PropTypes.oneOf(["dark", "light"]), // Assuming 'them' is a theme prop
};

export default AuthMenu;