import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import logo from "../assets/img/logo-w.svg";
import AuhtMenu from "../AuthenticationUtility/AuhtMenu";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const navItems = [
  { id: 1, href: "#Home", text: "Home" },
  { id: 2, href: "#OurSolutions", text: "Our Solutions" },
  { id: 3, href: "#KeyFeatures", text: "Key Features" },
  { id: 4, href: "#ReaderApp", text: "Products" },
  { id: 5, href: "#Price", text: "Price" },
];

const Header = () => {
  const { business } = useAuth();

  return (
    <Navbar fixed="top" expand="lg" id="navbar-example" style={{ backgroundColor: '#003C7F' }}>
      <Container>
        <Navbar.Brand href="#home">
          <Image src={logo} className="logo" width={200} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto fs-6 fw-bold ms-3 text-white">
            {navItems.map(item => (
              <Nav.Link key={item.id} href={item.href} className="text-white fw-normal">
                {item.text}
              </Nav.Link>
            ))}
          </Nav>
          <Nav className="align-content-center align-items-center justify-content-center">
            <AuhtMenu them="light" />
            <Link to={business && `/business/projects/list/${business.id}`} className="btn btn-outline-light ms-2">
              Your Business
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;