import React, { useEffect, lazy, Suspense } from "react";
import LandingFooter from "./Footer";
import Header from "./header";


// Lazy load components
const LandingSlider = lazy(() => import("./Slider"));
const LandingOurSolutions = lazy(() => import("./OurSolutions"));
const LandingKeyFeatures = lazy(() => import("./KeyFeatures"));
const LandingReaders = lazy(() => import("./Readers"));
const LandingMobileApp = lazy(() => import("./MobileApp"));
const LandingReaderApp = lazy(() => import("./ReaderApp"));
const LandingBusinessDashboard = lazy(() => import("./BusinessDashboard"));
const LandingPrice = lazy(() => import("./Price"));

const LandingIndex = () => {
  useEffect(() => {
    const root = document.documentElement;
    root.style.removeProperty("--bs-primary");
  }, []);

  return (
    <div>
      <Header />
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        className="scrollspy-example bg-body-tertiary rounded-2"
        tabIndex="0"
      >
        <LandingSlider />
       
        <Suspense fallback={<div>Loading...</div>}>
       
          <LandingOurSolutions />
          <LandingKeyFeatures />
          <LandingReaders />
          <LandingMobileApp />
          <LandingReaderApp />
          <LandingBusinessDashboard />
          <LandingPrice />
        </Suspense>
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingIndex;