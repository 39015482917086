import React from "react";
import { Link } from "react-router-dom";

const ModeratorHeader = (props) => {
  return (
    <>
      <div
        className="mb-3 p-2 border-top px-4"
        style={{ backgroundColor: "#003C7F" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <span className="d-flex align-content-center align-items-center justify-content-center ">
              <span className="text-white me-2">Moderator </span>
              {props &&
              props.rootName !== undefined &&
              props.rootLink !== undefined ? (
                <span className="text-white">
                  {" "}
                  <strong> {">"}</strong>{" "}
                  <Link to={props.rootLink} className="text-white">
                    {props.rootName}
                  </Link>
                </span>
              ) : props.rootName !== undefined ? (
                <span className="text-white">
                  {" "}
                  <strong> {">"}</strong> {props.rootName}
                </span>
              ) : (
                ""
              )}
              {props && props.currentRootName !== undefined && (
                <span className="text-white">
                  {" "}
                  <strong> {">"}</strong> {props.currentRootName}
                </span>
              )}
            </span>
          </div>
          <div className="d-flex justify-content-end">
            {props &&
              props.buttonName !== undefined &&
              props.buttonUrl !== undefined && (
                <Link to={props.buttonUrl} className="btn btn-sm btn-light">
                  {props.buttonName}
                </Link>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ModeratorHeader;
