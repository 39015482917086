/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import   NavMenu   from "../NavMenu";
import BusinessHeader from "../Businesses/BusinessHeader";
import { Link } from "react-router-dom";
import Footer from "../Footer";

export class LayoutBusinesses extends Component {
  static displayName = LayoutBusinesses.name;
  componentDidMount() {
    const root = document.documentElement;
    root.style.removeProperty("--bs-primary");
  }
  render() {
    return (
      <div>
        <NavMenu />
        <div className="bg-primary  mb-3 p-2 border-top">
          <div className="container d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <BusinessHeader textColor="text-light" />
            </div>
            <div className="d-flex justify-content-end">
              <Link
                to="/business/projects/create"
                className="btn btn-sm btn-light"
              >
                Create Project
              </Link>
            </div>
          </div>
        </div>
        <Container tag="main" className="main-container">
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
