import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import apiProjectModules from './Apis/ProjectModules';
import { Alert } from 'react-bootstrap';
import PageLoading from './Helpars/PageLoading';
import { useAuth } from './AuthenticationUtility/AuthContext';

const SideMenu = () => {
  const { logout } = useAuth();
  const params = useParams();

  const [modulesList, setModulesList] = useState([]);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [isPageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    ListProjectModules();
  }, []);

  const ListProjectModules = async () => {
    let response = await apiProjectModules.list(logout, params.hash_id);
    setModulesList(response.records);
    setStatus(response.status);
    setMessage(response.message);
    setPageLoading(false);
  };

  const IconList = () => {
    return (
      <div>
        {modulesList.map((item) => {
          const IconComponent = Icon[item.icon];
          return (
            <li key={item.id} className="nav-item mb-1">
              <Link to={item.url + params.hash_id} className="nav-link align-middle text-light px-1 px-sm-3 text-center text-sm-start">
                {/* Flex container for icon and title */}
                <div className="d-flex flex-column flex-sm-row align-items-center">
                  {/* Icon */}
                  <IconComponent size={20} />
                  {/* Title for mobile (below icon) */}
                  <span className="d-block d-sm-none mt-1" style={{ fontSize: 9 }}>{item.name}</span>
                  {/* Title for larger screens (next to icon) */}
                  <span className="ms-sm-2 d-none d-sm-block">{item.name}</span>
                </div>
              </Link>
            </li>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <>
          {status === "success" ? (
            <>
<ul 
  className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start px-1 px-sm-3 text-center text-sm-start" 
  id="menu"
>                <IconList />
              </ul>
            </>
          ) : (
            <Alert color="secondary" className="text-center">
              <h5 className="mb-3">{message}</h5>
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default SideMenu;