import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

/**
 * HelparPagination Component
 * A reusable pagination component that handles large datasets with ellipsis for better navigation.
 *
 * Props:
 * - pageCount: Total number of pages.
 * - currentPage: The currently active page.
 * - onPageChange: Function to handle page change.
 * - totalRecords: Total number of records (optional).
 * - recordsPerPage: Number of records displayed per page (optional).
 */
const HelparPagination = ({ pageCount, currentPage, onPageChange, totalRecords, recordsPerPage }) => {
    const [pagesToShow, setPagesToShow] = useState(10); // Number of pagination buttons to show

    // Adjust pagesToShow based on available pages
    useEffect(() => {
        if (pageCount < 10) {
            setPagesToShow(pageCount); // Dynamically adjust the number of buttons if there are fewer pages
        }
    }, [pageCount]);

    /**
     * handleClick
     * Handles the click event to change the page.
     *
     * @param {number} page - The page number to navigate to.
     */
    const handleClick = (page) => {
        onPageChange(page);
    };

    /**
     * renderPaginationItems
     * Renders the pagination buttons with ellipsis for large page counts.
     *
     * @returns {Array} - Array of Pagination items (buttons).
     */
    const renderPaginationItems = () => {
        const items = [];
        const ellipsis = <Pagination.Ellipsis key="ellipsis" disabled />;
        const halfWindow = Math.floor(pagesToShow / 2);

        // Calculate the first and last page numbers to display in pagination
        const firstPage = Math.max(1, currentPage - halfWindow);
        const lastPage = Math.min(pageCount, currentPage + halfWindow);

        // Add First Page button
        items.push(
            <Pagination.First
                key="first"
                onClick={() => handleClick(1)}
                disabled={currentPage === 1}
            />
        );

        // Add Ellipsis before first visible page if needed
        if (firstPage > 1) {
            items.push(<Pagination.Ellipsis key="ellipsis-start" disabled />);
        }

        // Add page buttons for the visible range
        for (let i = firstPage; i <= lastPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handleClick(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Add Ellipsis after last visible page if needed
        if (lastPage < pageCount) {
            items.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
        }

        // Add Last Page button
        items.push(
            <Pagination.Last
                key="last"
                onClick={() => handleClick(pageCount)}
                disabled={currentPage === pageCount}
            />
        );

        return items;
    };

    return (
        <div className="d-flex align-items-center justify-content-between">
            {/* Render the pagination items */}
            <Pagination className="pt-5">
                {renderPaginationItems()}
            </Pagination>
            
            {/* Show the total records and records per page (if available) */}
            {recordsPerPage && totalRecords && (
                <span className="fs-5 ms-3 text-secondary p-1">
                    {recordsPerPage}
                    <span className="fs-6"> from </span>
                    {totalRecords}
                    <span className="fs-6"> Records </span>
                </span>
            )}
        </div>
    );
};

export default HelparPagination;