import React, { Component } from "react";
import { Card, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo-v.svg"; // Replace with the actual path to your SVG file

export class LayoutAccount extends Component {
  static displayName = LayoutAccount.name;
  componentDidMount() {
    const root = document.documentElement;
    root.style.removeProperty("--bs-primary");
  }
  render() {
    return (
      <Container className="align-content-center justify-content-center" fluid>
        <Card
          className="card shadow-sm p-3 mb-5 mt-5 bg-body-tertiary rounded card-auth m-auto"
          style={{ width: "35rem" }}
        >
          <Card.Body className="text-center align-content-center  ">
            <Link
              to="/"
              className="mb-3 align-content-center align-items-center justify-content-center text-decoration-none "
            >
              <Image
                src={logo}
                width="150"
                className="mb-3"
              />
            </Link>
            {this.props.children}
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
