import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideMenu from "../SideMenu";
import ProjectHeader from "../Projects/Header";
import BusinessHeader from "../Businesses/BusinessHeader";
import Footer from "../Footer";
import AuhtMenu from "../AuthenticationUtility/AuhtMenu";

const LayoutProjects = (props) => {
  return (
    <div className="row flex-nowrap me-0">
      <div
        className="position-fixed col-auto col-md-3 col-xl-2 m-0 p-0"
        style={{ width: "16%" }}
      >
        <div
          className="align-items-center align-items-sm-start text-white min-vh-100 p-2 pt-0"
          style={{  background: "linear-gradient(135deg, #003C7F, #0066CC)" }}
        >
          <div className="d-flex px-3 mb-4 pt-2 border-white">
            <Link
              to="/"
              className="align-items-center py-3 text-dark text-decoration-none"
            >
              <div className="project-layout-logo"></div>
            </Link>
          </div>
          <SideMenu />
        </div>
      </div>
      <div className="col m-0 p-0">
        <header className="bg-light px-4">
          <div
            className="d-flex justify-content-between align-items-center p-1"
            style={{ marginLeft: "16%" }}
          >
            <BusinessHeader textColor="text-dark" />
            <div className="d-flex flex-row-reverse bg-body-tertiary">
              <ul className="nav"><AuhtMenu /></ul>
            </div>
          </div>
        </header>
        <div style={{ marginLeft: "16%" }}>
          <ProjectHeader
            rootName={props.rootName}
            rootLink={props.rootLink}
            currentRootName={props.currentRootName}
            buttonName={props.buttonName}
            buttonUrl={props.buttonUrl}
          />
          <Container tag="main" className="main-container">
            {props.children}
          </Container>
          <Footer marginLeft="16%" />
        </div>
      </div>
    </div>
  );
};

export default LayoutProjects;
